.header {
  display: flex;
  justify-content: space-between;
  padding: 8px 4px;
  @media all and (max-width: 500px) {
    flex-wrap: wrap;
  }

  &__navigation {
    display: flex;

    @media all and (max-width: 500px) {
      order: 2;
      width: 100%;
      padding-top: 10px;
    }
  }
}

.logo {
  &__wrapper {
    height: 34px;
  }

  &__image {
    height: 34px;
  }
}
