body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #efefef;
  padding-bottom: 58px;
}

* {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.button {
  padding: 7px 12px;
  margin-right: 7px;
  border: 1px solid #d0d0d0;
  background-color: #fff;
  border-radius: 4px;
  color: #3e3f3a;
  text-decoration: none;
  cursor: pointer;

  &__image {
    width: 15px;
    padding-right: 13px;
  }
}
